<template>
  <div class="control">
    <router-view />
  </div>
</template>

<script>
import { GET_TERMINAL_CURRENT_ID_SLUG } from '../../store/actions'
import { mapGetters } from 'vuex'

export default {
  name: 'Control',
  computed: {
    ...mapGetters({
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
    }),
  },
}
</script>

<style lang="sass">
.table-control
  *
    font-family: 'Inter', sans-serif
  &__menu
    cursor: pointer
    transition: transform .3s ease
    &:hover
      transform: scale(1.2)
    & + &
      margin-left: 17px
      +to(768px)
        margin-left: 0
        margin-top: 10px

  &__company
    *
      text-align: center

  .car,
  .allOption
    &0,
    &0
      font-weight: 500
      font-size: $fs-14
      line-height: 17px
      color: $color-control-primary
      text-align: center
    &1
      font-size: $fs-12
      line-height: 15px
      color: $color-control-secondary
      text-align: center

  .el-icon-d-caret
    margin-left: 3px

  .company,
  .allOption2
    font-size: $fs-14
    line-height: 17px
    color: $color-control-primary

  .culture
    &0
      font-size: 14px
      line-height: 17px
      color: $color-control-primary
      text-align: right
    &1
      font-size: 12px
      line-height: 15px
      text-align: right
      color: $color-control-secondary
  &__culture
    text-align: right
    div
      justify-content: flex-end
    .table-control-label__label
      text-align: center

  &__status
    text-align: center
    .status
      display: flex
      justify-content: center
      align-items: center
    .table-control-label__label
      text-align: center
  .status,
  .cultureStatusTimeslot,
  .allOption
    .badge
      font-weight: 500
      font-size: 10px
      line-height: 12px
      letter-spacing: 0.05em
      text-transform: uppercase
      color: $color-white
      background: $color-control-secondary
      border: 1px solid $color-control-secondary
      border-radius: 11px
      padding: 4px 10px
    .onWay,
    .culture-status-1
      background: $color-blue-light
      border: 1px solid $color-blue-light
    .arrived
      background: #008400
      border: 1px solid #008400
    .arrived
      background: #008400
      border: 1px solid #008400
    .late,
    .culture-status-0
      background: $color-white
      border: 1px solid #BA5300
      color: #BA5300
    .culture-status-1,
    .culture-status-0
      width: 117px
      text-align: center

  .cultureTimeslot
    line-height: 17px
    color: $color-control-primary
    text-align: right
    &1
      font-size: 12px
      line-height: 15px
      color: $color-control-secondary
    &2
      color: #E01A1A
    &3
      font-size: 12px
      line-height: 15px
      color: $color-control-secondary
  &__carCompany
    font-size: 14px
    line-height: 17px
    color: $color-control-primary
    &0
      font-weight: 500
    &1
      font-size: 12px
      line-height: 15px
      color: $color-control-secondary

  &__cultureStatusTimeslot,
  &__allOption
    .cultureStatusTimeslot,
    .allOption
      display: flex
      flex-direction: column
      .badge
        margin: 5px 0
  &__allOption
    align-items: flex-start

  .allOption
    &2
      margin-bottom: 5px
    &3
      font-size: 14px
      line-height: 17px
      color: $color-control-primary
    &4
      font-size: 12px
      line-height: 15px
      text-align: right
      color: $color-control-secondary

.table-control__cultureTimeslot
  div
    text-align: right
    justify-content: flex-end

.table-control__status
  div
    justify-content: center

.table-control__icon
  .cell
    padding: 0 20px 0 20px !important
    overflow: visible
    +to(768px)
      padding: 0 10px !important

.control-table
  //box-shadow: 0 3px 9px rgba(75, 81, 91, 0.1), 0 1px 9px rgba(0, 0, 0, 0.1)
  overflow: hidden
  &__navigation
    padding: 20px 16px 0
  .el-button
    &.router-link-exact-active
      background: $btn-orange
      border-radius: $btn-border-radius
      color: $color-white
      font-size: $fs-14
      border-color: $btn-orange

  &-exporters
    align-items: flex-start
    > * + *
      margin-top: 5px
</style>
